body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  background-color: #2f3e63;
  color: white;
}

@keyframes slideIn {
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.zoom-out {
  transform: scale(0.8);
  opacity: 0.5;
  transition: transform 0.8s, opacity 0.8s;
}

.slideImage {
  width: auto;
  height: 4rem;
}
